import axios, {AxiosError, AxiosRequestHeaders} from 'axios'
import {ConfigurationAsPromise} from '@/config/config'
import {
  Event as ClassroomEvent,
  Coach,
  WithId,
  RepeatingOption,
  ErrorType,
  CourseTag,
  TagList
} from '@/types/api'
import {
  ClassroomStudentService,
  ClassroomEventParams
} from './ClassroomStudentService.interfaces'
import locales from './ClassroomStudentService.locales.en.json'
import {RetryAmendableErrors} from '@/utils/retry-call'
import { getCurrentTimezone } from '@/utils/date'
import { downloadFile } from '@/utils/downloadFile'

function isAmendableAxiosError(error: any) {
  const axiosError = error as AxiosError
  return axiosError.isAxiosError && axiosError.response?.status == 409
}

export function createClassroomStudentService(
  eventParams: Readonly<ClassroomEventParams>
): ClassroomStudentService {
  const REPEATING_MAPPING: Record<string, RepeatingOption> = {
    DoesNotRepeat: RepeatingOption.DoesNotRepeat,
    Weekly: RepeatingOption.Weekly,
    Biweekly: RepeatingOption.Biweekly
  }

  function normalizeRepeatingOption(
    repeating: RepeatingOption | string
  ): RepeatingOption {
    return typeof repeating === 'string'
      ? REPEATING_MAPPING[repeating]
      : repeating
  }

  function normalizeClassroomEvent<E extends ClassroomEvent>(event: E): E {
    return {
      ...event,
      title: event.title ?? '',
      repeating: normalizeRepeatingOption(event.repeating)
    }
  }

  function errorToMessage(error: unknown): string {
    if (axios.isAxiosError(error)) {
      if (!error.response) {
        return locales.message_network_error
      }

      if (error.response.data.type == ErrorType.OutdatedDataRefreshNeeded) {
        return locales.message_need_refresh_error
      }

      if (error.response.data.type == ErrorType.SalesforceError) {
        return locales.message_update_error
      }

      switch (error.response.status) {
        case 401:
          return locales.message_unauthorized_error
        case 400:
          return error.response.data.message
        default:
          return locales.message_generic_error
      }
    } else {
      return locales.message_unexpected_error
    }
  }

  function createApiHeaders(): AxiosRequestHeaders {
    const headers: AxiosRequestHeaders = {
      Authorization: 'Bearer ' + eventParams.token
    }
    return headers
  }

  function createUrl(base: string, relative: string): string {
    // Adds a final slash if not exist
    const baseUrl = new URL(base.replace(/(\/)?$/, '/'))
    // Removes an initial slash if exist
    const safeRelative = relative.replace(/^(\/)?/, '')
    return new URL(safeRelative, baseUrl).toString()
  }

  async function createApiUrl(endpoint: string): Promise<string> {
    const {REST_API} = await ConfigurationAsPromise
    return createUrl(REST_API, endpoint)
  }

  async function retryAxiosErrors<T>(computation: () => Promise<T>) {
    return await RetryAmendableErrors(
      {MaximumRetries: 5, isAmendableError: isAmendableAxiosError},
      computation)
  }

  async function throwingErrorToMessage<T>(computation: () => Promise<T>) {
    try {
      return await computation()
    } catch (error) {
      const message = errorToMessage(error)
      throw new Error(message)
    }
  }

  async function retryToMessage<T>(computation: () => Promise<T>) {
    return await throwingErrorToMessage(async () => await retryAxiosErrors(computation))
  }

  async function fetchEvents(isLSAT: boolean): Promise<(ClassroomEvent & WithId)[]> {
    try {
      const endpoint = await createApiUrl('events')
      const response = await axios.get<(ClassroomEvent & WithId)[]>(endpoint, {
        headers: createApiHeaders(),
        params: {
          productId: eventParams.productId,
          jurisdictionId: isLSAT ? undefined : eventParams.jurisdictionId,
          isHidden: false,
          schoolId: eventParams.schoolId,
          year: isLSAT ? undefined : eventParams.year,
          period: isLSAT ? undefined : eventParams.period,
          timeZone: getCurrentTimezone()
        }
      })
      return response.data.map(normalizeClassroomEvent)
    } catch (error) {
      const message = errorToMessage(error)
      throw new Error(message)
    }
  }

  async function fetchWebinars(): Promise<(ClassroomEvent & WithId)[]> {
    try {
      const endpoint = await createApiUrl('events')
      const response = await axios.get<(ClassroomEvent & WithId)[]>(endpoint, {
        headers: createApiHeaders(),
        params: {
          eventType: 'Webinar',
          productId: eventParams.productId,
          jurisdictionId: eventParams.jurisdictionId,
          isHidden: false,
          year: eventParams.year,
          period: eventParams.period,
          timeZone: getCurrentTimezone()
        }
      })
      return response.data.map(normalizeClassroomEvent)
    } catch (error) {
      const message = errorToMessage(error)
      throw new Error(message)
    }
  }

  async function fetchAllCoaches(): Promise<(Coach & WithId)[]> {
    try {
      const endpoint = await createApiUrl('coaches')
      const response = await axios.get<(Coach & WithId)[]>(endpoint, {
        headers: createApiHeaders()
      })
      return response.data
    } catch (error) {
      const message = errorToMessage(error)
      throw new Error(message)
    }
  }

  async function fetchActiveTags(): Promise<(CourseTag)[]> {
    try {
      const endpoint = await createApiUrl('tags')
      const response = await axios.get<(TagList)>(endpoint, {
        headers: createApiHeaders()
      })
      const responseList = response.data as TagList
      return responseList.courseTags.filter(tag => tag.isActive === true)
    } catch (error) {
      const message = errorToMessage(error)
      throw new Error(message)
    }
  }

  async function fetchCoachById(coachId: string): Promise<Coach & WithId> {
    try {
      const endpoint = await createApiUrl('coaches/' + coachId)
      const response = await axios.get<Coach & WithId>(endpoint, {
        headers: createApiHeaders()
      })
      return response.data
    } catch (error) {
      const message = errorToMessage(error)
      throw new Error(message)
    }
  }

  async function fetchCoachProfilePicture(coachId: string): Promise<Blob | undefined> {
    try {
      const endpoint = await createApiUrl(`coaches/${coachId}/profile-picture`)
      const response = await axios.get<Blob | undefined>(endpoint, {
        headers: createApiHeaders(),
        responseType: 'blob'
      })
      return response.data
    } catch {
      return undefined
    }
  }

  async function addAttendeeToEvent(eventId: string, eTag: () => Promise<string>): Promise<string> {
    return await retryToMessage(async () => {
      const endpoint = await createApiUrl('events/' + eventId + '/attendees')

      const response = await axios.post<string>(
        endpoint,
        {
          attendee: eventParams.attendee,
          enrollmentId: eventParams.enrollmentId
        },
        {
          headers: {
            ...createApiHeaders(),
            'Content-Type': 'text/plain',
            'If-Match': await eTag()
          },
          responseType: 'text'
        }
      )

      return response.data
    })
  }

  async function removeAttendeeFromEvent(eventId: string, eTag: () => Promise<string>): Promise<string> {
    return await retryToMessage(async () => {
      const endpoint = await createApiUrl('events/' + eventId + '/attendees')

      const response = await axios.delete<string>(
        endpoint,
        {
          data: {
            attendee: eventParams.attendee,
            enrollmentId: eventParams.enrollmentId
          },
          headers: {
            ...createApiHeaders(),
            'Content-Type': 'text/plain',
            'If-Match': await eTag()
          },
          responseType: 'text'
        }
      )

      return response.data
    })
  }

  function fetchAttendee() {
    return eventParams.attendee
  }

  function fetchSchoolId() {
    return eventParams.schoolId ?? ''
  }

  function fetchProductId() {
    return eventParams.productId ?? ''
  }

  async function fetchEventDocument(eventId: string, documentName: string): Promise<Blob | undefined> {
    try {
      const endpoint = await createApiUrl(`events/${eventId}/documents/${documentName}`)
      const response = await axios.get<Blob | undefined>(endpoint, {
        headers: createApiHeaders(),
        responseType: 'blob'
      })
      return response.data
    } catch {
      return undefined
    }
  }

  async function downloadCalendarInvite(event: ClassroomEvent, isLSAT: boolean): Promise<void> {
    try {
      const endpoint = await createApiUrl('anon/calendar-invite')
      const response = await axios.get(
        endpoint, 
        {
          params: {
            title: (isLSAT ? 'PowerScore Classroom Central' : 'My BARBRI Connect') + ' Coaching Session',
            description: event.description + (event.description && event.meetingLink ? '\n\n' : '') + event.meetingLink,
            start: event.meetingOccurrences.map(meeting => meeting.start).join(','),
            end: event.meetingOccurrences.map(meeting => meeting.end).join(',')
          },
          headers: createApiHeaders(),
          responseType: 'blob'
      }
    )
    
    const filename = (isLSAT ? 'PowerScoreClassroomCentral' : 'MyBarbriConnect') + 'CoachingSessions.ics'
    downloadFile(response.data, filename)

    } catch {
      return undefined
    }
  }

  return {
    fetchEvents,
    fetchWebinars,
    fetchAllCoaches,
    fetchCoachById,
    fetchCoachProfilePicture,
    addAttendeeToEvent,
    removeAttendeeFromEvent,
    fetchAttendee,
    fetchSchoolId,
    fetchProductId,
    fetchActiveTags,
    fetchEventDocument,
    downloadCalendarInvite
  } 
}
