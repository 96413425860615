<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_174_6666"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="20"
    >
      <rect
        width="20"
        height="20"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_174_6666)">
      <path
        d="M9.9962 13.792C10.1485 13.792 10.2775 13.74 10.3833 13.6362C10.4891 13.5324 10.542 13.4038 10.542 13.2503V9.54195C10.542 9.38849 10.4904 9.25985 10.3874 9.15603C10.2844 9.05221 10.1567 9.00031 10.0044 9.00031C9.8521 9.00031 9.72307 9.05221 9.6173 9.15603C9.51154 9.25985 9.45866 9.38849 9.45866 9.54195V13.2503C9.45866 13.4038 9.51017 13.5324 9.6132 13.6362C9.71623 13.74 9.84389 13.792 9.9962 13.792ZM9.99591 7.7407C10.1618 7.7407 10.3023 7.68461 10.4174 7.57243C10.5325 7.46024 10.59 7.32122 10.59 7.15537C10.59 6.98952 10.5339 6.84903 10.4217 6.73391C10.3096 6.6188 10.1705 6.56124 10.0047 6.56124C9.83884 6.56124 9.69835 6.61734 9.58324 6.72953C9.46813 6.84173 9.41058 6.98075 9.41058 7.1466C9.41058 7.31244 9.46667 7.45292 9.57887 7.56803C9.69105 7.68315 9.83006 7.7407 9.99591 7.7407ZM10.0075 17.5836C8.96423 17.5836 7.98173 17.3863 7.05995 16.9915C6.13816 16.5968 5.33134 16.0537 4.63949 15.3622C3.94763 14.6706 3.40425 13.8645 3.00935 12.9437C2.61444 12.0229 2.41699 11.0391 2.41699 9.99216C2.41699 8.94524 2.61435 7.96437 3.00908 7.04953C3.4038 6.13469 3.94692 5.33134 4.63845 4.63949C5.32999 3.94763 6.13615 3.40425 7.05693 3.00935C7.97771 2.61444 8.96155 2.41699 10.0085 2.41699C11.0554 2.41699 12.0362 2.61435 12.9511 3.00908C13.8659 3.4038 14.6693 3.94692 15.3611 4.63845C16.053 5.32999 16.5964 6.13453 16.9913 7.05206C17.3862 7.96958 17.5836 8.94995 17.5836 9.99316C17.5836 11.0364 17.3863 12.0189 16.9915 12.9407C16.5968 13.8625 16.0537 14.6693 15.3622 15.3611C14.6706 16.053 13.8661 16.5964 12.9486 16.9913C12.031 17.3862 11.0507 17.5836 10.0075 17.5836ZM10.0003 16.5003C11.8059 16.5003 13.3406 15.8684 14.6045 14.6045C15.8684 13.3406 16.5003 11.8059 16.5003 10.0003C16.5003 8.19475 15.8684 6.66003 14.6045 5.39614C13.3406 4.13225 11.8059 3.50031 10.0003 3.50031C8.19475 3.50031 6.66003 4.13225 5.39614 5.39614C4.13225 6.66003 3.5003 8.19475 3.5003 10.0003C3.5003 11.8059 4.13225 13.3406 5.39614 14.6045C6.66003 15.8684 8.19475 16.5003 10.0003 16.5003Z"
        fill="#737373"
      />
    </g>
  </svg>
</template>
