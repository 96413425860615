
import { useClassroomStudentService } from '@/components/ClassroomStudentService'
import { Coach } from '@/types/api'
import { ref, onMounted } from 'vue'
import CirclePersonIcon from '@/icons/CirclePersonIcon.vue'

export default {
  components: {
    CirclePersonIcon
  },
  props: {
    coachId: {
      type: String,
      required: true
    }
  },
  setup(props: any) {
    const coach = ref<Coach>()
    const profilePictureSource = ref('')
    const classroomStudentService = useClassroomStudentService()

    onMounted(async () => {
      coach.value = await classroomStudentService.fetchCoachById(props.coachId)

      const profilePicture  = await classroomStudentService.fetchCoachProfilePicture(props.coachId)
      if (profilePicture) {
        profilePictureSource.value = window.URL.createObjectURL(profilePicture)
      }
    })

    return {
      coach,
      profilePictureSource
    }
  }
}
