import { ref, readonly } from 'vue'

document.head.insertAdjacentHTML(
  'beforeend',
  `
  <style>
    .host--bs-dialog-opened {
      overflow: hidden;
    }
  </style>
`
)

const count = ref(0)

export function useStackedDialogCounter() {
  function increment() {
    count.value += 1
    if (count.value === 1) {
      preventBodyScroll()
      preventBodyTab()
    }
  }

  function decrement() {
    if (count.value === 0) {
      console.warn('Attempted to decrement dialog stack count when it is zero.')
      return
    }
    count.value -= 1
    if (count.value === 0) {
      restoreBodyScroll()
      restoreBodyTab()
    }
  }

  function preventBodyScroll() {
    document.documentElement.classList.add('host--bs-dialog-opened')
    document.body.classList.add('host--bs-dialog-opened')
  }

  function restoreBodyScroll() {
    document.documentElement.classList.remove('host--bs-dialog-opened')
    document.body.classList.remove('host--bs-dialog-opened')
  }

  function preventBodyTab() {
    document.querySelectorAll<HTMLElement>('body > *:not(.bs-dialog)')
      .forEach(el => el.setAttribute('tabindex', '-1'))
  }
  
  function restoreBodyTab() {
    document.querySelectorAll<HTMLElement>('body > *:not(.bs-dialog)')
      .forEach(el => el.removeAttribute('tabindex'))
  }

  return {
    count: readonly(count),
    increment,
    decrement
  }
}
