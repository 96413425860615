<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_483_31"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect
        width="24"
        height="24"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_483_31)">
      <path
        d="M11.25 8.10767L7.92689 11.4308C7.78844 11.5692 7.6144 11.6401 7.40479 11.6433C7.19519 11.6465 7.01795 11.5756 6.87309 11.4308C6.7282 11.2859 6.65576 11.1103 6.65576 10.9039C6.65576 10.6975 6.7282 10.5218 6.87309 10.3769L11.3673 5.88272C11.5481 5.70196 11.759 5.61157 12 5.61157C12.241 5.61157 12.4519 5.70196 12.6327 5.88272L17.1269 10.3769C17.2654 10.5154 17.3362 10.6894 17.3394 10.899C17.3426 11.1087 17.2718 11.2859 17.1269 11.4308C16.982 11.5756 16.8064 11.6481 16.6 11.6481C16.3936 11.6481 16.218 11.5756 16.0731 11.4308L12.75 8.10767V16.9039C12.75 17.1167 12.6782 17.2949 12.5346 17.4385C12.391 17.5821 12.2128 17.6538 12 17.6538C11.7872 17.6538 11.609 17.5821 11.4654 17.4385C11.3218 17.2949 11.25 17.1167 11.25 16.9039V8.10767Z"
        fill="#1C75D4"
      />
    </g>
  </svg>
</template>