
import { defineComponent, PropType } from 'vue'
import { Event as ClassroomEvent } from '@/types/api'
import { formatDaysOfWeek, formatTimeRange, formatDateRange } from '@/utils/date'
import { useSessionRegistryStore } from '../SessionRegistryStore'

export default defineComponent({
  props: {
    coachingSession: {
      type: Object as PropType<ClassroomEvent>,
        required: true
      }
    },
    setup() {
    const { sessionState } = useSessionRegistryStore()
    return {
      formatDaysOfWeek,
      formatTimeRange,
      formatDateRange,
      sessionState
    }
  }
})
