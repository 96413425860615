import { Event as ClassroomEvent, RepeatingOption } from '@/types/api'
import { DateTime } from 'luxon'

export interface MeetingOccurrence {
  start: DateTime;
  end: DateTime;
}

// NOTE: The next occurrence is returned once the current one has reached it's end time,
// otherwise returns the current occurrence
export function calculateNextOccurrence(
  event: ClassroomEvent,
  now: DateTime = DateTime.now()
): MeetingOccurrence | null {
  if (!event.meetingOccurrences?.length) {
    return null
  }

  const endLocalTime = DateTime.fromISO(event.meetingOccurrences[0].end)

  switch (event.repeating) {
    case RepeatingOption.DoesNotRepeat:
      if (endLocalTime <= now) {
        return null
      }

      return {
        start: DateTime.fromISO(event.meetingOccurrences[0].start),
        end: endLocalTime
      } as MeetingOccurrence

    case RepeatingOption.Weekly:
    case RepeatingOption.Biweekly: {
      const nextOccurrence = event.meetingOccurrences.find(occureence => {
        const endTime = DateTime.fromISO(occureence.end)
        return endTime > now
      }) ?? null

      return nextOccurrence ? {
        start: DateTime.fromISO(nextOccurrence.start),
        end: DateTime.fromISO(nextOccurrence.end)
      } : null
    }
    default:
      return null
  }
}
