// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/img/Filter-default.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/img/Filter-active.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#microfilter[data-v-d51f79ac]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;height:50px;width:50px}#microfilter.active[data-v-d51f79ac]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");border-color:transparent!important}#search-section[data-v-d51f79ac]{padding:24px;background-color:#e8f1fb;border-radius:8px}.filter-header[data-v-d51f79ac]{color:#1c75d4;font-family:var(--babri-font-primary,\"Proxima Nova\"),sans-serif}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
