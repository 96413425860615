// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/img/Logo-Image.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".welcome-message[data-v-7b6f98a7]{padding:16px;box-shadow:0 4px 16px rgba(35,91,168,.16);border-radius:4px;background:#fff;margin-bottom:16px;font-size:var(--barbri-base-font-size,1rem);color:#fff;background:linear-gradient(45deg,#1c75d4,#235ba8);display:flex;align-items:center;padding:0 24px;text-align:left}.welcome-message__logo[data-v-7b6f98a7]{flex-shrink:0;width:75px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat;background-position:50%;align-self:stretch}.welcome-message__content[data-v-7b6f98a7]{flex:1;padding:24px}.welcome-message__title[data-v-7b6f98a7]{font-family:var(--babri-font-secondary,\"Goldplay\"),sans-serif;font-size:1.5em;font-weight:700;margin-bottom:8px;line-height:normal}.welcome-message__message[data-v-7b6f98a7]{font-family:var(--babri-font-primary,\"Proxima Nova\"),sans-serif;font-size:1.125em;line-height:1.125em}.welcome-message__button[data-v-7b6f98a7]{all:unset;display:block;padding:4px;border-radius:50%;box-sizing:border-box;transition:opacity .2s ease-out,box-shadow .2s ease-out;cursor:pointer;box-shadow:0 0 0 0 hsla(0,0%,100%,.25)}.welcome-message__button>svg[data-v-7b6f98a7]{display:block}.welcome-message__button[data-v-7b6f98a7]:hover{opacity:.7}.welcome-message__button[data-v-7b6f98a7]:active{opacity:1}.welcome-message__button[data-v-7b6f98a7]:focus{box-shadow:0 0 0 3px hsla(0,0%,100%,.25)}.welcome-message__button[data-v-7b6f98a7]:disabled{opacity:.5;cursor:auto}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
