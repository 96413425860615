import { MeetingOccurrence } from '@/components/ClassroomStudentService'
import { DateTime } from 'luxon'

export function remainsLessThan24Hours(occurrence: MeetingOccurrence): boolean {

  const startLocalDateTime = occurrence.start
  const endLocalDateTime = occurrence.end

  const currentDateTime= DateTime.now()

  const difference = Math.abs(startLocalDateTime.diff(currentDateTime,'hours').hours)

  return  (difference <= 24 && currentDateTime < startLocalDateTime) || 
          (currentDateTime >= startLocalDateTime && currentDateTime <= endLocalDateTime)
}

export function mapEventInstances(instances: string[], isIso: boolean = false) {
  return instances
    .map((element: string) => isIso ? DateTime.fromISO(element) : DateTime.fromFormat(element, 'yyyy-LL-dd'))
    .sort((leftElement: DateTime, rightElement: DateTime) => {
      if (leftElement < rightElement) {
        return -1
      }

      if (leftElement > rightElement) {
        return 1
      }

        return 0
    })    
}
