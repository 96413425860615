export interface Deferred<T> {
  promise: Promise<T>
  resolve(value: T | Promise<T>): void
  reject(reason?: any): void
}

function isValidDeferred<T>(deferred: Partial<Deferred<T>>): deferred is Deferred<T> {
  return Boolean(deferred.promise && deferred.resolve && deferred.reject)
}

export function createDeferred<T>(): Deferred<T> {
  const deferred: Partial<Deferred<T>> = {}
  deferred.promise = new Promise<T>((resolve, reject) => {
    deferred.resolve = resolve
    deferred.reject = reject
  })
  if (isValidDeferred(deferred)) {
    return deferred
  }
  throw new Error('Invalid Deferred')
}
