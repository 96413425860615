import type { InjectionKey } from 'vue'

export interface ToastItem {
  position:
    | 'top-center'
    | 'top-right'
    | 'top-left'
    | 'bottom-center'
    | 'bottom-left'
    | 'bottom-right'
  type: 'success' | 'failure' | 'info'
  title: string
  message: string
}

export interface ToastQueueViewModel {
  toasts: ToastItem[]
  head?: ToastItem
}

export type ToastItemArgs = Partial<ToastItem>

export type ToastItemArgsByType = Omit<ToastItemArgs, 'type'>

export interface ToastQueueActions {
  add(item: Partial<ToastItem>): void
  addSuccess(item: ToastItemArgsByType): void
  addError(item: ToastItemArgsByType): void
  addInfo(item: ToastItemArgsByType): void
  poll(): void
}

export interface ToastQueueStore {
  state: ToastQueueViewModel
  actions: ToastQueueActions
}

export const ToastQueueStoreKey: InjectionKey<ToastQueueStore> = Symbol('ToastQueueStore')
