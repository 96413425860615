import { createApp } from 'vue'
import ClassroomStudentApp from '@/components/ClassroomStudentApp'

const element = document.querySelector('classrooms-student-connect')
if (element instanceof HTMLElement) {
  const dataset = { ...element.dataset }
  for (const key in element.dataset) {
    delete element.dataset[key]
  }
  createApp(ClassroomStudentApp, {
    year: dataset['year'],
    period: dataset['period'],
    token: dataset['token'],
    attendee: dataset['attendee'],
    productId: dataset['productId'],
    productLabel: dataset['productLabel'],
    productValue: dataset['productValue'],
    jurisdictionLabel: dataset['jurisdictionLabel'],
    jurisdictionValue: dataset['jurisdictionValue'],
    enrollmentId: dataset['enrollmentId'],
    askQuestionUrl: dataset['askQuestionUrl']
  })
  .mount(element)
}
