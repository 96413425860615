interface TooltipByTag {
  tag: string;
  tooltip: string;
}

interface LabelByTag {
  tag: string;
  label: string;
}

const retakerValue = 'Retaker'

const tooltipByTag: TooltipByTag[] = [
  {
    tag: retakerValue,
    tooltip:
      'This session is geared towards the unique experience of preparing for a second administration of the bar exam.'
  }
]

const labelByTag: LabelByTag[] = [
  {
    tag: retakerValue,
    label: '(R) Retaker'
  }
]

function getLabelByTag(tag: string): string | undefined {
  const result = labelByTag.find(
    (element: LabelByTag) => element.tag === tag
  )

  if (result) return result.label
  else return ''
}

function getTooltipByTag(tag: string): string | undefined {
  const result = tooltipByTag.find(
    (element: TooltipByTag) => element.tag === tag
  )

  if (result) return result.tooltip
  else return ''
}

function hasRetakerTag (tags :string[]){
  return tags?.find(tag => tag === retakerValue)
}

export { getTooltipByTag ,getLabelByTag, hasRetakerTag }
