
import BsDialog from '@/components/BsDialog/BsDialog.vue'
import XCircleIcon from '@/icons/XCircleIcon.vue'
import BarButton from '@/components/BarButton'
import { useSessionRegistryStore } from '@/components/SessionRegistryStore'
import TableLoader from '@/components/TableLoader'
import CoachingSessionInfoBox from '../CoachingSessionInfoBox/CoachingSessionInfoBox.vue'
import { onUpdated, ref, watch } from 'vue'
import { Event as ClassroomEvent } from '@/types/api'
import { downloadClassroomEventIcsFile } from '@/utils/icalendar'
import errorLocales from '@/components/ClassroomStudentService/ClassroomStudentService.locales.en.json'
import LsatCoachingSessionInfoBox from '../CoachingSessionInfoBox/LsatCoachingSessionInfoBox.vue'

export default {
  components: {
    BsDialog,
    XCircleIcon,
    BarButton,
    TableLoader,
    CoachingSessionInfoBox,
    LsatCoachingSessionInfoBox
  },
  emits: ['CloseModal'],
  setup(props: any, context: any) {
    const { sessionState, sessionActions } = useSessionRegistryStore()
    const selectedEvent = ref<ClassroomEvent>()
    const isRegistering = ref(false)
    const isRegistered = ref(false)

    onUpdated(() => {
      isRegistered.value = false
    })

    watch(sessionState, (newValue) => {
      if (newValue.selectedSession?.event) {
        selectedEvent.value = newValue.selectedSession.event
      } else {
        isRegistered.value = false
      }
    })
    
    async function register() {
      isRegistering.value = true
      await sessionActions.registerToSession()

      if (sessionState.error && sessionState.error != errorLocales.message_need_refresh_error) {
        isRegistering.value = false
        return
      }
      
      sessionActions.unSelectSession()
      await sessionActions.loadSessions()
      
      if (sessionState.error) {
        context.emit('CloseModal')
      } else {
        isRegistered.value = true
        isRegistering.value = false
      }
    }
    
    function addToCalendar() {
      if (selectedEvent.value) {
        downloadClassroomEventIcsFile(selectedEvent.value, sessionState.isLSAT)
      }
    }

    return {
      sessionState,
      selectedEvent,
      isRegistering,
      isRegistered,
      register,
      addToCalendar
    }
  }
}
