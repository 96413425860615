import {Either, Left, Right, throwLeft} from '@/utils/either'

interface RetryConfig {
  MaximumRetries: number;
}

async function RetryWithBackoff<Response>(
  config: RetryConfig,
  computation: () => Promise<Response>
): Promise<Response> {
  let backoffTime = 1
  let lastError = null
  for (let i = 0; i < config.MaximumRetries; i++) {
    try {
      return await computation()
    } catch (Error) {
      console.log('Error with computation. Retrying.')
      backoffTime *= 2
      lastError = Error

      const backoff = Math.random() * backoffTime * 1000
      await sleep(backoff)
    }
  }

  throw lastError
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

async function throwOnlyAmendableErrors<T>(computation: () => Promise<T>, config: AmendableConfig): Promise<Either<any, T>> {
  try {
    return new Right(await computation())
  } catch (error: any) {
    if (config.isAmendableError(error))
      throw error
    return new Left(error)
  }
}

interface AmendableConfig {
  isAmendableError: (error: any) => boolean
}

type RetryAmendableConfig = AmendableConfig & RetryConfig

async function RetryOnlyAmendableError<T>(config: RetryAmendableConfig, computation: () => Promise<T>): Promise<Either<any, T>> {
  return await RetryWithBackoff(
    config,
    async () => await throwOnlyAmendableErrors(computation, config)
  )
}

async function RetryAmendableErrors<T>(config: RetryAmendableConfig, computation: () => Promise<T>): Promise<T> {
  return throwLeft(await RetryOnlyAmendableError(config, computation))
}

export {RetryWithBackoff, RetryOnlyAmendableError, RetryConfig, RetryAmendableErrors, sleep}
