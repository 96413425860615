import { reactive } from 'vue'
import {
  CoachingSessionState,
  CoachingSessionActions,
  CoachingSessionStore
} from './CoachingSessionStore.interfaces'
import { useClassroomStudentService, calculateNextOccurrence } from '@/components/ClassroomStudentService'
import { formatDisplaySchedule, formatUsDateTime } from '@/utils/date'
import { mapEventInstances, remainsLessThan24Hours } from './CoachingSessionStore.utils'
import locales from './CoachingSessionStore.locales.en.json'
import { SessionListItemViewModel } from '../SessionListItem/SessionListItem.interfaces'

export function createCoachingSessionStore(): CoachingSessionStore {

  const classroomStudentService = useClassroomStudentService()

  const coachingState: CoachingSessionState = reactive({
    session: reactive({
      isLoading: false
    }),
    change: reactive({
      isLoading: false
    }),
    about: reactive({
      isLoading: false
    }),
    scheduleDetail: reactive({      
    }),
    cancel: reactive({
    }),
    questionUrl: ''
  })

  const coachingActions: CoachingSessionActions = {
    loadCoachingSession,
    loadAboutCoach,
    removeFromSession
  }

  async function removeFromSession() {
    const attendee = coachingState.cancel.model?.attendee
    const eventId = coachingState.cancel.model?.event.id

    coachingState.cancel.error = ''

    if(!attendee) {
      coachingState.cancel.error = 'Could not retrieve Attendee info'
      return
    }

    if(!eventId) {
      coachingState.cancel.error = 'Could not retrieve Session info'
      return
    }

    if(!coachingState.cancel.model?.event.attendees.some(att => att.toUpperCase() === attendee.toUpperCase())) {
      coachingState.cancel.error = 'Attendee is not signed up for this event'
      return
    }

    try {
        const getEtagFactory = () => {
          return async () => coachingState.cancel.model!.event.eTag
        }

        await classroomStudentService.removeAttendeeFromEvent(
          eventId,
          getEtagFactory()
        )
    } catch (error) {
      coachingState.cancel.error =
        error instanceof Error
          ? error.message
          : locales.message_unexpected_error
    } 
  }

  async function loadCoachingSession(sessions: SessionListItemViewModel[]) {
    const attendee = classroomStudentService.fetchAttendee()
    const currentSession = sessions.filter(s => s.attendees?.some(att => att.toUpperCase() === attendee.toUpperCase()))[0]
    const event = { ...currentSession.event, id: currentSession.sessionId }
    try {
      coachingState.session.error = ''
      coachingState.session.isLoading = true
      if (!event) {
        return
      }
      const coach = await classroomStudentService.fetchCoachById(event.coachId)
      const profilePicture = await classroomStudentService.fetchCoachProfilePicture(event.coachId)
      
      const nextOccurrence = calculateNextOccurrence(event)                

      const showNextOccurrence = nextOccurrence && remainsLessThan24Hours(nextOccurrence)
      const expired = nextOccurrence === null
      coachingState.session.model = {
        session: reactive({
          sessionId: event.id,
          displaySchedule: formatDisplaySchedule(event)          
        }),
        coach: reactive({
          coachId: coach.id,
          displayName: coach.name,
          profilePicture: profilePicture
        }),
        nextSession: showNextOccurrence ? reactive({
          url: event.meetingLink,
          displayDate: formatUsDateTime(nextOccurrence.start),
          platform: 'zoom'
        }) : undefined,
        expired        
      }

      coachingState.scheduleDetail = {
        model :{
          addedInstances : event.datesChanges != null ? mapEventInstances(event.datesChanges.added) : [],
          removedInstances : event.datesChanges != null ? mapEventInstances(event.datesChanges.removed) : [],
          event: event
        }        
      }

      coachingState.cancel = {
        model :{
          attendee: attendee,
          event: event
        }
      }

    } catch (error) {
      coachingState.session.error = error instanceof Error
        ? error.message
        : locales.message_unexpected_error
    } finally {
      coachingState.session.isLoading = false
    }
  }

  async function loadAboutCoach() {
    try {
      coachingState.about.error = ''
      coachingState.about.isLoading = true
      const eventId = coachingState.session.model?.coach.coachId
      if (eventId) {
        const coach = await classroomStudentService.fetchCoachById(eventId)
        coachingState.about.model = {
          displayName: coach.name,
          message: coach.aboutMe
        }
      }
    } catch (error) {
      coachingState.about.error = error instanceof Error
        ? error.message
        : locales.message_unexpected_error
    } finally {
      coachingState.about.isLoading = false
    }
  }

  return { coachingState, coachingActions }
}
