import type { InjectionKey } from 'vue'
import type { CoachingSessionViewModel } from '@/components/CoachingSessionView'
import type { CoachingSessionAboutCoachViewModel } from '@/components/CoachingSessionAboutCoach'
import { CoachingSessionScheduleDetailViewModel } from '../CoachingSessionScheduleDetail/CoachingSessionScheduleDetail.interfaces'
import { CoachingSessionCancelViewModel } from '../CoachingSessionCancel/CoachingSessionCancel.interfaces'
import { SessionListItemViewModel } from '../SessionListItem/SessionListItem.interfaces'

export interface CoachingSessionState {
  session: {
    model?: CoachingSessionViewModel
    isLoading: boolean
    error?: string
  },
  about: {
    model?: CoachingSessionAboutCoachViewModel
    isLoading: boolean
    error?: string
  },
  scheduleDetail:{
    model?: CoachingSessionScheduleDetailViewModel    
    error?: string
  },
  cancel:{
    model?: CoachingSessionCancelViewModel
    error?: string
  }
  questionUrl: string
}

export interface CoachingSessionActions {
  loadCoachingSession(sessions: SessionListItemViewModel[]): Promise<void>
  loadAboutCoach(): Promise<void>
  removeFromSession(): Promise<void>
}

export interface CoachingSessionStore {
  coachingState: CoachingSessionState
  coachingActions: CoachingSessionActions
}

export const CoachingSessionStoreKey: InjectionKey<CoachingSessionStore> = Symbol('CoachingSessionStore')
