import type { InjectionKey } from 'vue'
import { Event as ClassroomEvent, Coach, CourseTag, WithId } from '@/types/api'

export interface ClassroomEventParams {
  attendee: string
  token: string
  productId?: string
  jurisdictionId?: string
  enrollmentId?: string
  schoolId?: string
  year?: number
  period?: number
}

export interface ClassroomStudentService {
  fetchEvents(isLSAT: boolean): Promise<(ClassroomEvent & WithId)[]>
  fetchWebinars(): Promise<(ClassroomEvent & WithId)[]>
  fetchAllCoaches(): Promise<(Coach & WithId)[]>
  fetchCoachById(coachId: string): Promise<Coach & WithId>
  fetchCoachProfilePicture(coachId: string): Promise<Blob | undefined>
  addAttendeeToEvent(eventId: string, eTag: ()=> Promise<string>): Promise<string>,
  removeAttendeeFromEvent(eventId: string, eTag: ()=> Promise<string>): Promise<string>
  fetchAttendee(): string
  fetchSchoolId(): string
  fetchProductId(): string
  fetchActiveTags(): Promise<(CourseTag)[]>
  fetchEventDocument(eventId: string, documentName: string): Promise<Blob | undefined>
}

export const ClassroomStudentServiceKey: InjectionKey<ClassroomStudentService> = Symbol('ClassroomStudentService')
