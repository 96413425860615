import { EventAttributes, createEvents } from 'ics'
import { DateTime } from 'luxon'
import { Event as ClassroomEvent } from '@/types/api'
import { downloadFile } from './downloadFile'

export async function downloadClassroomEventIcsFile(event: ClassroomEvent, isLSAT: boolean) {
    const calendarEvents: EventAttributes[] = []

    event.meetingOccurrences.forEach((meeting) => {
      const startDate: DateTime = DateTime.fromISO(meeting.start)
      const endDate: DateTime = DateTime.fromISO(meeting.end)
      const calendarEvent: EventAttributes = {
        start: [ startDate.year, startDate.month, startDate.day, startDate.hour, startDate.minute ],
        end: [ endDate.year, endDate.month, endDate.day, endDate.hour, endDate.minute ],
        title: (isLSAT ? 'PowerScore Classroom Central' : 'My BARBRI Connect') + ' Coaching Session',
        description: event.description + (event.description && event.meetingLink ? '\n\n' : '') + event.meetingLink,
        organizer: { name: 'Barbri', email: 'noreply@barbri.com' }
      }
      calendarEvents.push(calendarEvent)
    })

    const filename = (isLSAT ? 'PowerScoreClassroomCentral' : 'MyBarbriConnect') + 'CoachingSessions.ics'
    const file: Blob = await new Promise((resolve, reject) => {
      createEvents(calendarEvents, (error, value) => {
        if (error) {
          reject(error)
        }
        resolve(new File([value], filename, { type: 'text/calendar' }))
      })
    })
    downloadFile(file, filename)
  }