<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_310_28"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect
        width="24"
        height="24"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_310_28)">
      <path
        d="M12 19.4962L16.0577 15.4577C16.2064 15.309 16.3804 15.233 16.5798 15.2298C16.7792 15.2266 16.9564 15.3026 17.1115 15.4577C17.2667 15.6128 17.3442 15.791 17.3442 15.9923C17.3442 16.1936 17.2667 16.3718 17.1115 16.5269L13.0788 20.575C12.7852 20.8686 12.4256 21.0154 12 21.0154C11.5744 21.0154 11.2148 20.8686 10.9212 20.575L6.8731 16.5269C6.72438 16.3782 6.65098 16.2016 6.6529 15.9971C6.65482 15.7926 6.73334 15.6128 6.88847 15.4577C7.04359 15.3026 7.22179 15.2266 7.42307 15.2298C7.62436 15.233 7.80256 15.309 7.95767 15.4577L12 19.4962ZM12 4.50382L7.95767 8.52689C7.80896 8.67561 7.63492 8.74901 7.43557 8.74709C7.23622 8.74518 7.05898 8.67178 6.90385 8.52689C6.74873 8.37178 6.66861 8.19358 6.66347 7.99229C6.65834 7.79103 6.73334 7.61283 6.88847 7.45769L10.9212 3.42499C11.2148 3.13141 11.5744 2.98462 12 2.98462C12.4256 2.98462 12.7852 3.13141 13.0788 3.42499L17.1115 7.45769C17.2602 7.60641 17.3336 7.78301 17.3317 7.98749C17.3298 8.19198 17.2513 8.37178 17.0961 8.52689C16.941 8.67178 16.7654 8.74518 16.5692 8.74709C16.3731 8.74901 16.1974 8.67561 16.0423 8.52689L12 4.50382Z"
        fill="#235BA8"
      />
    </g>
  </svg>
</template>