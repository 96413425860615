
import { defineComponent, PropType, computed, unref } from 'vue'
import { BsCheckboxMode } from './BsCheckbox.interfaces'
import type { Validation } from '@vuelidate/core'
import { createAutoIncrementIdProp } from '@/utils/props'

export const BsCheckbox = defineComponent({
  name: 'BsCheckbox',
  props: {
    id: createAutoIncrementIdProp('BsCheckbox'),
    modelValue: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    label: {
      type: String as PropType<string>,
      default: ''
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    validation: {
      type: Object as PropType<Validation>,
      default: undefined
    },
    mode: {
      type: String as PropType<string>,
      default: 'regular'
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const modeClassMapping: Record<BsCheckboxMode, string> = {
      regular: 'BsCheckbox-label--regular ms-2 my-auto fs-6',
      pill: 'BsCheckbox-label--pill badge rounded-pill fw-normal px-3'
    }

    const dirty = computed<boolean>(() => {
      return props.validation?.$dirty ?? false
    })
    const invalid = computed<boolean>(() => {
      return props.validation?.$invalid ?? false
    })
    const invalidMessage = computed<string>(() => {
      const message = props.validation?.$errors?.[0]?.$message ?? ''
      return unref(message)
    })

    function handleChange(event: Event) {
      const target = event.target as HTMLInputElement
      emit('update:modelValue', target.checked)
    }

    return {
      dirty,
      invalid,
      invalidMessage,
      handleChange,
      modeClassMapping
    }
  }
})

export default BsCheckbox
