import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36af8974"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col checkbox-col" }
const _hoisted_2 = ["id", "value", "checked", "disabled"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'BsCheckbox',
      { 'row g-0' : _ctx.mode == 'regular' }
    ]),
    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        id: _ctx.id,
        class: _normalizeClass([
          _ctx.mode == 'regular' ? 'form-check-input' : 'd-none',
          _ctx.dirty && _ctx.invalid ? 'is-invalid' : '',
        ]),
        type: "checkbox",
        value: _ctx.modelValue,
        checked: _ctx.modelValue,
        disabled: _ctx.disabled,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
      }, null, 10, _hoisted_2)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass({ 'col d-flex' : _ctx.mode == 'regular' })
    }, [
      _createElementVNode("label", {
        for: _ctx.id,
        class: _normalizeClass([
          _ctx.modeClassMapping[_ctx.mode],
          _ctx.modelValue ? 'active' : 'inactive',
          { 'BsCheckbox__disabled' : _ctx.disabled }
        ])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
      ], 10, _hoisted_3)
    ], 2)
  ], 2))
}