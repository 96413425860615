<template>
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.48715 13.0128H13.0127V12.5955C13.0127 11.8596 12.6167 11.2591 11.8248 10.7939C11.0328 10.3287 10.0078 10.0961 8.74994 10.0961C7.49204 10.0961 6.46711 10.3287 5.67513 10.7939C4.88314 11.2591 4.48715 11.8596 4.48715 12.5955V13.0128ZM8.74994 8.57048C9.31683 8.57048 9.79882 8.37192 10.1959 7.97481C10.593 7.5777 10.7916 7.0957 10.7916 6.52881C10.7916 5.96195 10.593 5.47996 10.1959 5.08285C9.79882 4.68573 9.31683 4.48718 8.74994 4.48718C8.18308 4.48718 7.70109 4.68573 7.30398 5.08285C6.90686 5.47996 6.7083 5.96195 6.7083 6.52881C6.7083 7.0957 6.90686 7.5777 7.30398 7.97481C7.70109 8.37192 8.18308 8.57048 8.74994 8.57048ZM2.10895 17.4999C1.51963 17.4999 1.0208 17.2958 0.612471 16.8874C0.204157 16.4791 0 15.9803 0 15.391V2.10898C0 1.51966 0.204157 1.02083 0.612471 0.6125C1.0208 0.204167 1.51963 0 2.10895 0H15.3909C15.9803 0 16.4791 0.204167 16.8874 0.6125C17.2957 1.02083 17.4999 1.51966 17.4999 2.10898V7.44873L20.7037 4.24492C20.8757 4.07291 21.069 4.02991 21.2837 4.11591C21.4983 4.20192 21.6056 4.36607 21.6056 4.60836V12.8916C21.6056 13.1339 21.4983 13.298 21.2837 13.384C21.069 13.47 20.8757 13.427 20.7037 13.255L17.4999 10.0512V15.391C17.4999 15.9803 17.2957 16.4791 16.8874 16.8874C16.4791 17.2958 15.9803 17.4999 15.3909 17.4999H2.10895ZM2.10895 15.75H15.3909C15.4957 15.75 15.5817 15.7163 15.649 15.649C15.7163 15.5817 15.7499 15.4957 15.7499 15.391V2.10898C15.7499 2.00428 15.7163 1.91826 15.649 1.85095C15.5817 1.78363 15.4957 1.74997 15.3909 1.74997H2.10895C2.00425 1.74997 1.91823 1.78363 1.85092 1.85095C1.7836 1.91826 1.74994 2.00428 1.74994 2.10898V15.391C1.74994 15.4957 1.7836 15.5817 1.85092 15.649C1.91823 15.7163 2.00425 15.75 2.10895 15.75Z"
      fill="#235BA8"
    />
  </svg>
</template>