interface IConfig extends Record<string, unknown> {
  APP_TITLE: string;
  REST_API: string;
  AUTH_CLIENT_ID?: string;
  AUTH_ENDPOINT?: string;
  NEED_HELP_URL?: string;
}

interface JsonFile {
  [key: string]: string;
}

import axios from 'axios'
import { createDeferred } from '@/utils/async'

let Configuration: Partial<IConfig> = {}
const deferredConfiguration = createDeferred<IConfig>()
const ConfigurationAsPromise = deferredConfiguration.promise
const basePath = getBasePath()

function getBasePath() {
  if (process.env.BASE_URL === '/') {
    return location.origin
  }
  if (process.env.BASE_URL !== 'auto') {
    return process.env.BASE_URL
  }
  if (document.currentScript instanceof HTMLScriptElement) {
    const currentScriptSrc = new URL(document.currentScript.src)
    return currentScriptSrc.origin
  }
  else return location.origin
}

function isValidConfig(config: Record<string, unknown>): config is IConfig {
  if (!config.APP_TITLE || typeof config.APP_TITLE !== 'string') {
    return false
  }
  if (!config.REST_API || typeof config.REST_API !== 'string') {
    return false
  }
  // AUTH_CLIENT_ID is not mandatory, barbriWeb can provide a token
  if (config.AUTH_CLIENT_ID && typeof config.AUTH_CLIENT_ID !== 'string') {
    return false
  }
  // AUTH_ENDPOINT is not mandatory, barbriWeb can provide a token
  if (config.AUTH_ENDPOINT && typeof config.AUTH_ENDPOINT !== 'string') {
    return false
  }
  if (config.ASK_QUESTION_URL && typeof config.ASK_QUESTION_URL !== 'string') {
    return false
  }
  return true
}

const InitConfiguration = async (): Promise<void> => {
  const endpoint = new URL('/config.values.json', basePath).toString()
  const response = await axios.get(endpoint)
  const configValues: JsonFile = response.status === 404 ? {} : response.data
  const config: Record<string, unknown> = {}
  for (const value in configValues) {
    config[value] = parse(
      configValues[value],
      process.env[`VUE_APP_${value}`]
    )
  }
  Configuration = config
  if (isValidConfig(config)) {
    deferredConfiguration.resolve(config)
  } else {
    deferredConfiguration.reject(new Error('Invalid configuration'))
  }
}

function parse(value: any, fallback: any) {
  if (typeof value === 'undefined' || value === '') {
    return fallback
  }
  switch (typeof fallback) {
    case 'boolean':
      return !!JSON.parse(value)
    case 'number':
      return JSON.parse(value)
    default:
      return value
  }
}

export {
  InitConfiguration,
  Configuration,
  ConfigurationAsPromise,
  basePath
}
