import { computed } from 'vue'
import BsToastQueueStore from './BsToastQueueStore.vue'
import { ToastItemArgsByType, ToastQueueStoreKey } from './BsToastQueueStore.interfaces'
import { injectStrict } from '@/utils/di'

export * from './BsToastQueueStore.interfaces'

export default BsToastQueueStore

export function useToastQueueStore() {
  return injectStrict(ToastQueueStoreKey)
}

export function useToast() {
  const { state, actions } = useToastQueueStore()

  const count = computed(() => state.toasts.length)

  function showSuccessToast(item: ToastItemArgsByType) {
    actions.addSuccess(item)
  }

  function showErrorToast(item: ToastItemArgsByType) {
    actions.addError(item)
  }

  function showInfoToast(item: ToastItemArgsByType) {
    actions.addInfo(item)
  }

  return {
    count,
    showSuccessToast,
    showErrorToast,
    showInfoToast
  }
}
