import { SessionListItemViewModel } from '@/components/SessionListItem'

export function computeTotalSessionPages(filteredSessions: SessionListItemViewModel[], sessionsPerPage: number) {
    return Math.ceil(filteredSessions.length / sessionsPerPage)
}

export function computePaginatedSessions(
    filteredSessions: SessionListItemViewModel[],
    sessionsPerPage: number,
    currentPage: number) {
    const start = (currentPage - 1) * sessionsPerPage
    const end = currentPage * sessionsPerPage
    return filteredSessions.slice(start, end)
}