<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M8.59278 18.3076H15.0992C15.3998 18.3076 15.6507 18.207 15.8519 18.0059C16.0531 17.8047 16.1537 17.5538 16.1537 17.2532V14.866L19.1668 17.2823C19.4285 17.4932 19.7097 17.5265 20.0104 17.3822C20.311 17.2378 20.4613 16.9996 20.4613 16.6676V11.3324C20.4613 11.0003 20.311 10.7621 20.0104 10.6178C19.7097 10.4734 19.4285 10.5067 19.1668 10.7176L16.1537 13.134V10.7468C16.1537 10.4461 16.0531 10.1952 15.8519 9.99408C15.6507 9.7929 15.3998 9.69232 15.0992 9.69232H8.59278C8.29214 9.69232 8.04124 9.7929 7.84007 9.99408C7.6389 10.1952 7.53831 10.4461 7.53831 10.7468V17.2532C7.53831 17.5538 7.6389 17.8047 7.84007 18.0059C8.04124 18.207 8.29214 18.3076 8.59278 18.3076ZM5.02549 22.7499C4.43617 22.7499 3.93734 22.5458 3.529 22.1374C3.12067 21.7291 2.9165 21.2303 2.9165 20.641V7.35898C2.9165 6.76966 3.12067 6.27083 3.529 5.8625C3.93734 5.45417 4.43617 5.25 5.02549 5.25H22.9741C23.5635 5.25 24.0623 5.45417 24.4706 5.8625C24.879 6.27083 25.0831 6.76966 25.0831 7.35898V20.641C25.0831 21.2303 24.879 21.7291 24.4706 22.1374C24.0623 22.5458 23.5635 22.7499 22.9741 22.7499H5.02549ZM5.02549 21H22.9741C23.0788 21 23.1649 20.9663 23.2322 20.899C23.2995 20.8317 23.3331 20.7457 23.3331 20.641V7.35898C23.3331 7.25428 23.2995 7.16826 23.2322 7.10095C23.1649 7.03363 23.0788 6.99997 22.9741 6.99997H5.02549C4.92078 6.99997 4.83477 7.03363 4.76745 7.10095C4.70013 7.16826 4.66648 7.25428 4.66648 7.35898V20.641C4.66648 20.7457 4.70013 20.8317 4.76745 20.899C4.83477 20.9663 4.92078 21 5.02549 21Z"
        fill="#235BA8"
      />
    </g>
  </svg>
</template>
