import { reactive, computed } from 'vue'
import type {
  ToastQueueStore,
  ToastQueueViewModel,
  ToastQueueActions,
  ToastItem,
  ToastItemArgsByType
} from './BsToastQueueStore.interfaces'
import locales from './BsToastQueueStore.locales.en.json'

export function createToastQueueStore(): ToastQueueStore {
  const state: ToastQueueViewModel = reactive({
    toasts: reactive([]),
    head: computed(() => state.toasts[0])
  })

  const actions: ToastQueueActions = {
    add,
    addSuccess,
    addError,
    addInfo,
    poll
  }

  function createDefaultToastItem(): ToastItem {
    return {
      type: 'success',
      position: 'bottom-center',
      title: '',
      message: ''
    }
  }

  function add(item: Partial<ToastItem>) {
    state.toasts.push({
      ...createDefaultToastItem(),
      ...item
    })
  }

  function addSuccess(item: ToastItemArgsByType) {
    state.toasts.push({
      ...createDefaultToastItem(),
      title: locales.success,
      ...item,
      type: 'success'
    })
  }

  function addError(item: ToastItemArgsByType) {
    state.toasts.push({
      ...createDefaultToastItem(),
      title: locales.error,
      ...item,
      type: 'failure'
    })
  }

  function addInfo(item: ToastItemArgsByType) {
    state.toasts.push({
      ...createDefaultToastItem(),
      title: locales.error,
      ...item,
      type: 'info'
    })
  }

  function poll() {
    state.toasts.shift()
  }

  return { state, actions }
}
