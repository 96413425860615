<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_333_1844"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32"
    >
      <rect
        width="32"
        height="32"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_333_1844)">
      <path
        d="M13.7743 18.5282L10.6769 15.4308C10.4923 15.2461 10.2602 15.1517 9.98073 15.1474C9.70124 15.1431 9.46492 15.2376 9.27177 15.4308C9.07861 15.6239 8.98203 15.8581 8.98203 16.1333C8.98203 16.4085 9.07861 16.6427 9.27177 16.8358L12.9307 20.4948C13.1718 20.7358 13.453 20.8563 13.7743 20.8563C14.0957 20.8563 14.3769 20.7358 14.6179 20.4948L22.0358 13.0769C22.2205 12.8923 22.3149 12.6602 22.3192 12.3807C22.3235 12.1012 22.229 11.8649 22.0358 11.6718C21.8427 11.4786 21.6085 11.382 21.3333 11.382C21.0581 11.382 20.8239 11.4786 20.6308 11.6718L13.7743 18.5282ZM15.6689 28.3333C13.917 28.3333 12.2702 28.0008 10.7287 27.3359C9.1872 26.671 7.84629 25.7687 6.706 24.6289C5.56569 23.4891 4.66294 22.1488 3.99777 20.608C3.33259 19.0671 3 17.4208 3 15.6689C3 13.917 3.33244 12.2702 3.99733 10.7287C4.66222 9.1872 5.56456 7.84629 6.70433 6.706C7.84413 5.56569 9.18446 4.66295 10.7253 3.99777C12.2661 3.33259 13.9125 3 15.6644 3C17.4163 3 19.063 3.33245 20.6045 3.99733C22.1461 4.66222 23.487 5.56456 24.6273 6.70433C25.7676 7.84413 26.6703 9.18446 27.3355 10.7253C28.0007 12.2661 28.3333 13.9125 28.3333 15.6644C28.3333 17.4163 28.0008 19.063 27.3359 20.6045C26.671 22.1461 25.7687 23.487 24.6289 24.6273C23.4891 25.7676 22.1488 26.6703 20.608 27.3355C19.0671 28.0007 17.4208 28.3333 15.6689 28.3333ZM15.6666 26.3333C18.6444 26.3333 21.1666 25.3 23.2333 23.2333C25.3 21.1666 26.3333 18.6444 26.3333 15.6666C26.3333 12.6889 25.3 10.1666 23.2333 8.09997C21.1666 6.0333 18.6444 4.99997 15.6666 4.99997C12.6889 4.99997 10.1666 6.0333 8.09997 8.09997C6.0333 10.1666 4.99997 12.6889 4.99997 15.6666C4.99997 18.6444 6.0333 21.1666 8.09997 23.2333C10.1666 25.3 12.6889 26.3333 15.6666 26.3333Z"
        fill="#6C757D"
      />
    </g>
  </svg>
</template>