class Left<L> {
  public constructor(value: L) {
    this.value = value
  }

  value: L
}

class Right<R> {
  public constructor(value: R) {
    this.value = value
  }

  value: R
}

type Either<L, R> = Left<L> | Right<R>

function throwLeft<T>(either: Either<any, T>) {
  if (either instanceof Left) {
    throw either.value
  } else {
    return either.value
  }
}

export {Either, Left, Right, throwLeft}