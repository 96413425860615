import { Prop, PropType } from 'vue'

export function createAutoIncrementId(prefix: string) {
  let counter = 0
  return () => `${prefix}_${counter++}`
}

export function createAutoIncrementIdProp(prefix: string): Prop<string> {
  let counter = 0
  return {
    type: String as PropType<string>,
    default: () => `${prefix}_${counter++}`
  }
}

