export type Guid = string
export type Url = string
export type Email = string
export type IsoDate = string //date in ISO 8601 (https://en.wikipedia.org/wiki/ISO_8601)
export type IsoTimeTZ = string //time with time zone in ISO 8601 (https://en.wikipedia.org/wiki/ISO_8601)
export type Id = string

export interface WithId {
  id: Guid
}

export interface Coach {
  name: string
  aboutMe: string
  products: Id[]
  jurisdictions: Id[]
}

interface DatesChanges {
  added: string[]
  removed: string[]
}

export interface MeetingOccurrence {
  start: string
  end: string
}

export interface Event {
  productId: Id
  jurisdictionId: Id
  eventType: 'Webinar' | 'CoachingSession'
  coachId: Guid
  description: string
  title: string
  meetingLink: Url
  year?: number
  period?: number
  startDate: IsoDate | null
  endDate: IsoDate | null
  schoolId: string
  startTime: IsoTimeTZ | null
  endTime: IsoTimeTZ | null
  repeating: RepeatingOption
  attendees: Email[]
  maxAttendees: number
  timeZone: string
  attendeesUnfilteredCount: number
  eTag: string
  tags?: string[]
  datesChanges?: DatesChanges
  meetingOccurrences: MeetingOccurrence[]
  documentFileNames: string[]
  recordingLink: Url
}

export enum RepeatingOption {
  DoesNotRepeat = 1,
  Weekly = 2,
  Biweekly = 3,
  Custom = 4
}

export enum ErrorType {
  ConflictingEventAttendee = 'ConflictingEventAttendee',
  ConflictingEvent = 'ConflictingEvent',
  Unauthorized = 'Unauthorized',
  ValidationError = 'ValidationError',
  UniqueViolation = 'UniqueViolation',
  UnauthorizedAttendee = 'UnauthorizedAttendee',
  AddedAttendeeToExpiredEvent = 'AddedAttendeeToExpiredEvent',
  MaximumAttendeesViolated = 'MaximumAttendeesViolated',
  OutdatedDataRefreshNeeded = 'OutdatedDataRefreshNeeded',
  SalesforceError = 'SalesforceError'
}

export interface TagList {
  courseTags: CourseTag[]
}

export interface CourseTag {
  title: string,
  abbreviation: string,
  color: string,
  description: string,
  isActive: boolean
}