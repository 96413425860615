<template>
  <svg
    width="30"
    height="24"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_83_3360"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="6"
      y="0"
      width="24"
      height="24"
    >
      <rect
        x="30"
        y="24"
        width="24"
        height="24"
        transform="rotate(-180 30 24)"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_83_3360)">
      <path
        d="M16.5269 6.8731L21.0211 11.3673C21.1147 11.4609 21.1808 11.5612 21.2192 11.6683C21.2577 11.7753 21.2769 11.8859 21.2769 12C21.2769 12.1205 21.2577 12.2327 21.2192 12.3365C21.1808 12.4404 21.1147 12.5391 21.0211 12.6327L16.5269 17.1269C16.3821 17.2718 16.2064 17.3442 16 17.3442C15.7936 17.3442 15.618 17.2718 15.4731 17.1269C15.3282 16.982 15.2558 16.8064 15.2558 16.6C15.2558 16.3936 15.3282 16.218 15.4731 16.0731L19.5462 12L15.4731 7.9269C15.3282 7.78203 15.2558 7.6064 15.2558 7.4C15.2558 7.1936 15.3282 7.01797 15.4731 6.8731C15.618 6.72822 15.7936 6.65577 16 6.65577C16.2064 6.65577 16.3821 6.72822 16.5269 6.8731Z"
        fill="#235BA8"
      />
    </g>
    <mask
      id="mask1_83_3360"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect
        x="24"
        y="24"
        width="24"
        height="24"
        transform="rotate(-180 24 24)"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask1_83_3360)">
      <path
        d="M10.5269 6.8731L15.0211 11.3673C15.1147 11.4609 15.1808 11.5612 15.2192 11.6683C15.2577 11.7753 15.2769 11.8859 15.2769 12C15.2769 12.1205 15.2577 12.2327 15.2192 12.3365C15.1808 12.4404 15.1147 12.5391 15.0211 12.6327L10.5269 17.1269C10.3821 17.2718 10.2064 17.3442 10 17.3442C9.79362 17.3442 9.61798 17.2718 9.47312 17.1269C9.32823 16.982 9.25579 16.8064 9.25579 16.6C9.25579 16.3936 9.32823 16.218 9.47312 16.0731L13.5462 12L9.47312 7.9269C9.32823 7.78203 9.25579 7.6064 9.25579 7.4C9.25579 7.1936 9.32823 7.01797 9.47312 6.8731C9.61798 6.72822 9.79362 6.65577 10 6.65577C10.2064 6.65577 10.3821 6.72822 10.5269 6.8731Z"
        fill="#235BA8"
      />
    </g>
  </svg>
</template>
