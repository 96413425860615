<template>
  <svg
    width="30"
    height="24"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_83_3349"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect
        width="24"
        height="24"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_83_3349)">
      <path
        d="M13.4731 17.1269L8.97889 12.6327C8.88529 12.5391 8.81926 12.4388 8.78081 12.3317C8.74235 12.2247 8.72311 12.1141 8.72311 12C8.72311 11.8795 8.74235 11.7673 8.78081 11.6635C8.81926 11.5596 8.88529 11.4609 8.97889 11.3673L13.4731 6.8731C13.618 6.72822 13.7936 6.65578 14 6.65578C14.2064 6.65578 14.382 6.72822 14.5269 6.8731C14.6718 7.01797 14.7442 7.1936 14.7442 7.4C14.7442 7.6064 14.6718 7.78203 14.5269 7.9269L10.4538 12L14.5269 16.0731C14.6718 16.218 14.7442 16.3936 14.7442 16.6C14.7442 16.8064 14.6718 16.982 14.5269 17.1269C14.382 17.2718 14.2064 17.3442 14 17.3442C13.7936 17.3442 13.618 17.2718 13.4731 17.1269Z"
        fill="#235BA8"
      />
    </g>
    <mask
      id="mask1_83_3349"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="6"
      y="0"
      width="24"
      height="24"
    >
      <rect
        x="6"
        width="24"
        height="24"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask1_83_3349)">
      <path
        d="M19.4731 17.1269L14.9789 12.6327C14.8853 12.5391 14.8193 12.4388 14.7808 12.3317C14.7423 12.2247 14.7231 12.1141 14.7231 12C14.7231 11.8795 14.7423 11.7673 14.7808 11.6635C14.8193 11.5596 14.8853 11.4609 14.9789 11.3673L19.4731 6.8731C19.618 6.72822 19.7936 6.65578 20 6.65578C20.2064 6.65578 20.382 6.72822 20.5269 6.8731C20.6718 7.01797 20.7442 7.1936 20.7442 7.4C20.7442 7.6064 20.6718 7.78203 20.5269 7.9269L16.4538 12L20.5269 16.0731C20.6718 16.218 20.7442 16.3936 20.7442 16.6C20.7442 16.8064 20.6718 16.982 20.5269 17.1269C20.382 17.2718 20.2064 17.3442 20 17.3442C19.7936 17.3442 19.618 17.2718 19.4731 17.1269Z"
        fill="#235BA8"
      />
    </g>
  </svg>
</template>
