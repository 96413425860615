
import { defineComponent } from 'vue'
import locales from './TableLoader.locales.en.json'

export default defineComponent({
  name: 'TableLoader',
  setup() {
    return {
      locales
    }
  }
})
