<template>
  <bs-dialog
    class-names="session-info-modal"
    close-on-escape
    close-on-backdrop-click
  >
    <template #header="{ close }">
      <div class="header d-flex justify-content-between">
        <h5 class="title m-0">
          Session{{ coachingSession.isLSAT ? "/Instructor" : "" }} Info
        </h5>
        <button
          class="button"
          @click="close"
        >
          <x-circle-icon />
        </button>
      </div>
    </template>

    <div class="modal_content mt-4">
      <lsat-coaching-session-info-box
        v-if="coachingSession.isLSAT"
        class="mb-3"
        :coaching-session="coachingSession.event"
      />
      <coach-info-box
        v-if="coachingSession.isLSAT"
        :coach-id="coachingSession.coachId"
      />
      <coaching-session-info-box
        v-else
        :coaching-session="coachingSession.event"
      />
    </div>

    <template #footer="{ close }">
      <div class="footer float-end m-3 mt-1">
        <bar-button
          mode="outline"
          class="footer-button"
          @click="close"
        >
          &lt;&nbsp;&nbsp;Go Back
        </bar-button>
      </div>
    </template>
  </bs-dialog>
</template>

<script>
import BsDialog from '@/components/BsDialog/BsDialog.vue'
import XCircleIcon from '@/icons/XCircleIcon.vue'
import CoachingSessionInfoBox from '../CoachingSessionInfoBox/CoachingSessionInfoBox.vue'
import LsatCoachingSessionInfoBox from '../CoachingSessionInfoBox/LsatCoachingSessionInfoBox.vue'
import CoachInfoBox from '../CoachingSessionInfoBox/CoachInfoBox.vue'
import BarButton from '@/components/BarButton'
import { SessionListItemViewModel } from '../SessionListItem/SessionListItem.interfaces'

export default {
  components: {
    BsDialog,
    XCircleIcon,
    CoachingSessionInfoBox,
    LsatCoachingSessionInfoBox,
    CoachInfoBox,
    BarButton
  },
  props: {
    coachingSession: SessionListItemViewModel
  }
}
</script>

<style lang="scss" scoped>

.session-info-modal {
  font-size: var(--barbri-base-font-size, 1rem);
  font-family: var(--babri-font-primary, "Proxima Nova"), sans-serif;
  $primary-color: #235ba8;

  .title {
    font-family: var(--babri-font-secondary, "Goldplay"), sans-serif;
    font-weight: 100 !important;
  }

  svg {
    vertical-align: unset !important;
  }

  .modal{
    &_content {
      width: 608px;
      margin-left: 24px;
      margin-right: 24px;
      line-height: 32px;
    }
  }

  .footer-button {
    line-height: 48px !important;
    border: 1px solid #1C75D4; 
  }
}

.bs-dialog {
  --dialog-vertical: flex-start;
  --dialog-margin: 16px;
  --dialog-gap: 16px;
  --dialog-backdrop-color: rgba(35, 91, 168, 0.32);
  --dialog-border-color: rgba(35, 91, 168, 0.4);
  --dialog-height: auto;
}
</style>