import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61bee63a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header d-flex justify-content-between" }
const _hoisted_2 = { class: "title m-0" }
const _hoisted_3 = ["disabled", "onClick"]
const _hoisted_4 = { class: "modal_content mt-4" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "mb-4" }
const _hoisted_8 = { class: "footer float-end m-3 mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x_circle_icon = _resolveComponent("x-circle-icon")!
  const _component_table_loader = _resolveComponent("table-loader")!
  const _component_lsat_coaching_session_info_box = _resolveComponent("lsat-coaching-session-info-box")!
  const _component_coaching_session_info_box = _resolveComponent("coaching-session-info-box")!
  const _component_bar_button = _resolveComponent("bar-button")!
  const _component_bs_dialog = _resolveComponent("bs-dialog")!

  return (_openBlock(), _createBlock(_component_bs_dialog, {
    "class-names": "session-registration",
    "close-on-escape": "",
    "close-on-backdrop-click": ""
  }, {
    header: _withCtx(({ close }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h5", _hoisted_2, _toDisplayString($setup.isRegistered
            ? 'Registration Successful'
            : 'Register for ' + ($setup.sessionState.isLSAT ? 'Classroom' : 'Coaching') + ' Session'), 1),
        _createElementVNode("button", {
          class: "button",
          disabled: $setup.isRegistering,
          onClick: close
        }, [
          _createVNode(_component_x_circle_icon)
        ], 8, _hoisted_3)
      ])
    ]),
    footer: _withCtx(({ close }) => [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_bar_button, {
          mode: "outline",
          class: "footer-button",
          disabled: $setup.isRegistering,
          onClick: ($event: any) => ($setup.isRegistered ? $setup.addToCalendar() : close())
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.isRegistered ? 'Add to Calendar' : 'Cancel'), 1)
          ]),
          _: 2
        }, 1032, ["disabled", "onClick"]),
        _createVNode(_component_bar_button, {
          class: "footer-button",
          disabled: $setup.isRegistering,
          onClick: ($event: any) => ($setup.isRegistered ? close() : $setup.register())
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.isRegistered ? 'Back to Sessions' : 'Register'), 1)
          ]),
          _: 2
        }, 1032, ["disabled", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        ($setup.isRegistering)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_table_loader, { class: "big-margin" })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString($setup.isRegistered
            ? 'You did it! You\'re registered for the following session' + ($setup.sessionState.isLSAT ? ':' : 's:')
            : 'Are you sure you want to register for the following session' + ($setup.sessionState.isLSAT ? '?' : 's?')), 1),
              ($setup.sessionState.isLSAT)
                ? (_openBlock(), _createBlock(_component_lsat_coaching_session_info_box, {
                    key: 0,
                    "coaching-session": $setup.selectedEvent,
                    "include-description": !$setup.isRegistered
                  }, null, 8, ["coaching-session", "include-description"]))
                : (_openBlock(), _createBlock(_component_coaching_session_info_box, {
                    key: 1,
                    "coaching-session": $setup.selectedEvent
                  }, null, 8, ["coaching-session"]))
            ]))
      ])
    ]),
    _: 1
  }))
}