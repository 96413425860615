<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M10.5269 6.8731L15.0211 11.3673C15.1147 11.4609 15.1808 11.5612 15.2192 11.6683C15.2577 11.7753 15.2769 11.8859 15.2769 12C15.2769 12.1205 15.2577 12.2327 15.2192 12.3365C15.1808 12.4404 15.1147 12.5391 15.0211 12.6327L10.5269 17.1269C10.3821 17.2718 10.2064 17.3442 10 17.3442C9.79362 17.3442 9.61798 17.2718 9.47312 17.1269C9.32823 16.982 9.25579 16.8064 9.25579 16.6C9.25579 16.3936 9.32823 16.218 9.47312 16.0731L13.5462 12L9.47312 7.9269C9.32823 7.78203 9.25579 7.6064 9.25579 7.4C9.25579 7.1936 9.32823 7.01797 9.47312 6.8731C9.61798 6.72822 9.79362 6.65577 10 6.65577C10.2064 6.65577 10.3821 6.72822 10.5269 6.8731Z"
        fill="#235BA8"
      />
    </g>
  </svg>
</template>
