import { MeetingOccurrence } from '@/types/api'
import { DateTime } from 'luxon'

const formatTime = (dateTime: DateTime) => dateTime.toLocaleString({
  hour: 'numeric',
  hour12: true,
  minute: '2-digit'
})

export function checkIsValidEventTiming(meetingOccurrences: MeetingOccurrence[]) {
  return meetingOccurrences?.length > 0
}

export function createDateFormatter(meetingOccurrences: MeetingOccurrence[]) {
  const startLocalDateTime = DateTime.fromISO(meetingOccurrences[0].start)
  const endTimeLocalDateTime = DateTime.fromISO(meetingOccurrences[0].end)
  const endLocalDateTime = DateTime.fromISO(meetingOccurrences.at(-1)!.end)
  
  function formatDateRange(): string {
    const startMonthDayText = startLocalDateTime.toLocaleString({
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    })
    const endMonthDayText = endLocalDateTime.toLocaleString({
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    })

    return `${startMonthDayText} - ${endMonthDayText}`
  }
  
  function formatLocalTimeRange(): string {
    return formatTime(startLocalDateTime) + ' - ' + formatTime(endTimeLocalDateTime)
  }

  return {
    startLocalDateTime,
    endLocalDateTime,
    formatDateRange,
    formatLocalTimeRange
  }
}
