import { SessionListItemViewModel } from '@/components/SessionListItem/SessionListItem.interfaces'
import { DateTime } from 'luxon'
import ZonesMaps from '@/utils/TimeZones.map.json'
import { RepeatingOption, Event as ClassroomEvent } from '@/types/api'
import { checkIsValidEventTiming, createDateFormatter } from '@/components/SessionRegistryStore/SessionRegistryStore.utils'

const USALocale = 'en-US'
const GBLocale = 'en-GB'

interface TimeZoneMap{
  iana:string,
  utc:string
}

export function getCurrentTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function getLocale(): string {
  return Intl.DateTimeFormat().resolvedOptions().locale == USALocale
    ? USALocale
    : GBLocale
}

export function formatUsDate(date: DateTime | string) {
  const formattedDate = typeof (date) == 'string' ? DateTime.fromISO(date) : date

  return formattedDate.toLocaleString(DateTime.DATE_MED)
}

export function formatUsDateTime(date: DateTime | string) {
  const formattedDate =  typeof(date) == 'string' ? DateTime.fromISO(date) : date

  return `${formattedDate.toLocaleString(DateTime.DATE_MED)}, ${formattedDate.toFormat('hh:mm a')}`
}

export function formatUsWeeklyTime(
  startDate: Date | string,
  endDate: Date | string,
  plural: boolean = false
) {
  const safeStartDate = new Date(startDate)
  const weekday = DateTime.fromJSDate(safeStartDate)
    .setZone(getCurrentTimezone())
    .toFormat('cccc')
  const startTime = DateTime.fromJSDate(safeStartDate).toFormat('h:mm a')

  const safeEndDate = new Date(endDate)
  const endTime = DateTime.fromJSDate(safeEndDate).toFormat('h:mm a')

  return `${weekday}${plural ? 's' : ''} | ${startTime} - ${endTime}`
}

export function formatTableDateTime(sessionListItem : SessionListItemViewModel) {
  const startDateTime = sessionListItem.localStartDateTime
  const endDateTime = sessionListItem.localEndDateTime
  const repeatingOption = sessionListItem.repeatingOption
  if (repeatingOption !== RepeatingOption.DoesNotRepeat) {
    return startDateTime.toLocaleString('en-us', {weekday: 'long', month: 'short', day: 'numeric', year: 'numeric'}).replace('day', repeatingOption === RepeatingOption.Biweekly ? 'days Biweekly' :'days') + 
    ' - ' + 
    endDateTime.toLocaleString('en-us', {month: 'short', day: 'numeric', year: 'numeric'})
  }
  return startDateTime.toLocaleString('en-us', {weekday: 'long', month: 'short', day: 'numeric', year: 'numeric'})
}

export function getUTCZoneFromIANA() {
  const ianaTimeZone = getCurrentTimezone()

  const timeZoneMap = ZonesMaps.names.find((element: TimeZoneMap) => element.iana === ianaTimeZone)

  return timeZoneMap?.utc ?? ianaTimeZone
}

export function formatDisplaySchedule(event: ClassroomEvent): string {
  const utcTimeZone = getUTCZoneFromIANA()

  const startDate = DateTime.fromISO(
    event.startDate + 'T' + event.startTime + 'Z'
  )
    .toLocal()
    .toJSDate()
  const endDate = DateTime.fromISO(
    event.endDate + 'T' + event.endTime + 'Z'
  )
    .toLocal()
    .toJSDate()

  switch (event.repeating) {
    case RepeatingOption.Biweekly:
      return formatUsWeeklyTime(startDate, endDate, true) + ', Biweekly ' + utcTimeZone
    case RepeatingOption.Weekly:
      return formatUsWeeklyTime(startDate, endDate, true) + ' ' + utcTimeZone
    case RepeatingOption.DoesNotRepeat:
      return formatUsWeeklyTime(startDate, endDate, false) + ' ' + utcTimeZone
    default:
      return ''
  }
}

export function formatDaysOfWeek(event: ClassroomEvent): string {
  if (!checkIsValidEventTiming(event.meetingOccurrences)) {
    return ''
  }

  const formatter = createDateFormatter(event.meetingOccurrences)
  const weekday = formatter.startLocalDateTime.toJSDate().toLocaleString('en-us',{weekday: 'long'})

  switch (event.repeating) {
    case RepeatingOption.Biweekly:
      return weekday + 's Biweekly'
    case RepeatingOption.Weekly:
      return weekday + 's'
    case RepeatingOption.DoesNotRepeat:
      return weekday
    default:
      return ''
  }
}

export function formatTimeRange(event: ClassroomEvent): string {
  if (!checkIsValidEventTiming(event.meetingOccurrences)) {
    return ''
  }
  const formatter = createDateFormatter(event.meetingOccurrences)
  const localTimeRange = formatter.formatLocalTimeRange()
  const utcTimeZone = getUTCZoneFromIANA()
  return localTimeRange + ' ' + utcTimeZone
}

export function formatDateRange(event: ClassroomEvent): string {
  if (!checkIsValidEventTiming(event.meetingOccurrences)) {
    return ''
  }
  const formatter = createDateFormatter(event.meetingOccurrences)
  return formatter.formatDateRange()
}