<template>
  <svg
    width="18"
    height="23"
    viewBox="0 0 18 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.24997 10.7916C5.00168 10.7916 4.79378 10.7079 4.62627 10.5403C4.45876 10.3728 4.375 10.1649 4.375 9.91664C4.375 9.66835 4.45876 9.46045 4.62627 9.29294C4.79378 9.12542 5.00168 9.04167 5.24997 9.04167H12.25C12.4983 9.04167 12.7062 9.12542 12.8737 9.29294C13.0412 9.46045 13.1249 9.66835 13.1249 9.91664C13.1249 10.1649 13.0412 10.3728 12.8737 10.5403C12.7062 10.7079 12.4983 10.7916 12.25 10.7916H5.24997ZM5.24997 6.12494C5.00168 6.12494 4.79378 6.04118 4.62627 5.87367C4.45876 5.70616 4.375 5.49826 4.375 5.24997C4.375 5.00169 4.45876 4.79379 4.62627 4.62627C4.79378 4.45876 5.00168 4.375 5.24997 4.375H12.25C12.4983 4.375 12.7062 4.45876 12.8737 4.62627C13.0412 4.79379 13.1249 5.00169 13.1249 5.24997C13.1249 5.49826 13.0412 5.70616 12.8737 5.87367C12.7062 6.04118 12.4983 6.12494 12.25 6.12494H5.24997ZM1.74997 13.7083H10.5C11.019 13.7083 11.4991 13.8205 11.9403 14.0449C12.3816 14.2692 12.7562 14.5863 13.0644 14.9961L15.75 18.4962V2.10898C15.75 2.00428 15.7163 1.91826 15.649 1.85095C15.5817 1.78363 15.4957 1.74997 15.391 1.74997H2.10898C2.00427 1.74997 1.91826 1.78363 1.85095 1.85095C1.78363 1.91826 1.74997 2.00428 1.74997 2.10898V13.7083ZM2.10898 20.4166H15.0119L11.6868 16.0663C11.5403 15.8733 11.3656 15.7238 11.163 15.6176C10.9603 15.5114 10.7393 15.4583 10.5 15.4583H1.74997V20.0576C1.74997 20.1623 1.78363 20.2483 1.85095 20.3157C1.91826 20.383 2.00427 20.4166 2.10898 20.4166ZM15.391 22.1666H2.10898C1.51966 22.1666 1.02083 21.9624 0.6125 21.5541C0.204167 21.1458 0 20.6469 0 20.0576V2.10898C0 1.51966 0.204167 1.02083 0.6125 0.6125C1.02083 0.204167 1.51966 0 2.10898 0H15.391C15.9803 0 16.4791 0.204167 16.8874 0.6125C17.2958 1.02083 17.4999 1.51966 17.4999 2.10898V20.0576C17.4999 20.6469 17.2958 21.1458 16.8874 21.5541C16.4791 21.9624 15.9803 22.1666 15.391 22.1666Z"
      fill="#235BA8"
    />
  </svg>
</template>