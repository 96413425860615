<template>
  <div class="session-info-box p-3 rounded-2">
    <div><b>Sessions: </b> {{ formatDaysOfWeek(coachingSession) }} </div>
    <div><b>Time: </b> {{ formatTimeRange(coachingSession) }} </div>
    <div><b>Dates: </b> {{ formatDateRange(coachingSession) }} </div>
  </div>
</template>

<script>
import { Event as ClassroomEvent } from '@/types/api'
import { formatDaysOfWeek, formatTimeRange, formatDateRange } from '@/utils/date'

export default {
  props: {
    coachingSession: ClassroomEvent
  },
  setup() {
    return {
      formatDaysOfWeek,
      formatTimeRange,
      formatDateRange
    }
  }
}
</script>

<style>
  .session-info-box {
    border: 1px solid #1C75D466;
    font-family: var(--babri-font-primary, "Proxima Nova"), sans-serif;
    color: #000000;
  }
</style>