<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_483_13"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect 
        width="24"
        height="24"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_483_13)">
      <path
        d="M11.25 14.7962V5.99998C11.25 5.78716 11.3218 5.60896 11.4654 5.46538C11.609 5.32179 11.7872 5.25 12 5.25C12.2128 5.25 12.391 5.32179 12.5346 5.46538C12.6782 5.60896 12.75 5.78716 12.75 5.99998V14.7962L16.0731 11.4731C16.2115 11.3346 16.3856 11.2638 16.5952 11.2606C16.8048 11.2574 16.982 11.3282 17.1269 11.4731C17.2718 11.6179 17.3442 11.7936 17.3442 12C17.3442 12.2064 17.2718 12.382 17.1269 12.5269L12.6327 17.0211C12.4519 17.2019 12.241 17.2923 12 17.2923C11.759 17.2923 11.5481 17.2019 11.3673 17.0211L6.8731 12.5269C6.73463 12.3884 6.6638 12.2144 6.6606 12.0048C6.65738 11.7952 6.72821 11.6179 6.8731 11.4731C7.01796 11.3282 7.1936 11.2558 7.4 11.2558C7.6064 11.2558 7.78203 11.3282 7.9269 11.4731L11.25 14.7962Z"
        fill="#235BA8"
      />
    </g>
  </svg>
</template>