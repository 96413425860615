import { injectStrict } from '@/utils/di'
import { CoachingSessionStoreKey } from './CoachingSessionStore.interfaces'

export { default } from './CoachingSessionStore.vue'

export * from './CoachingSessionStore.interfaces'

export function useCoachingSessionStore() {
  return injectStrict(CoachingSessionStoreKey)
}
