import { injectStrict } from '@/utils/di'
import { ClassroomStudentServiceKey } from './ClassroomStudentService.interfaces'

export { default } from './ClassroomStudentService.vue'

export * from './ClassroomStudentService.interfaces'

export * from './ClassroomStudentService.utils'

export function useClassroomStudentService() {
  return injectStrict(ClassroomStudentServiceKey)
}
