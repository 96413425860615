import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c9d1a19"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = {
  key: 0,
  class: "heading"
}
const _hoisted_3 = { class: "sub-heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckCircle = _resolveComponent("CheckCircle")!
  const _component_ExclamationCircle = _resolveComponent("ExclamationCircle")!
  const _component_InfoCircle = _resolveComponent("InfoCircle")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass('toast-main-segment' + ' ' + _ctx.position + ' ' + _ctx.type),
              role: "alert",
              "aria-live": "assertive"
            }, [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createElementVNode("div", _hoisted_1, [
                  (_ctx.type === 'success')
                    ? (_openBlock(), _createBlock(_component_CheckCircle, {
                        key: 0,
                        class: "toast-icon"
                      }))
                    : (_ctx.type === 'failure')
                      ? (_openBlock(), _createBlock(_component_ExclamationCircle, {
                          key: 1,
                          class: "toast-icon"
                        }))
                      : (_openBlock(), _createBlock(_component_InfoCircle, {
                          key: 2,
                          class: "toast-icon"
                        })),
                  _createElementVNode("div", null, [
                    (_ctx.title)
                      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.title), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.message), 1)
                  ])
                ])
              ], true)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}