import { injectStrict } from '@/utils/di'
import { SessionRegistryStoreKey } from './SessionRegistryStore.interfaces'

export * from './SessionRegistryStore.interfaces'

export { default } from './SessionRegistryStore.vue'

export function useSessionRegistryStore() {
  return injectStrict(SessionRegistryStoreKey)
}
