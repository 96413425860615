import { MeetingOccurrence } from '@/types/api'
import { DateTime } from 'luxon'

const formatTime = (dateTime: DateTime) => dateTime.toLocaleString({
  hour: 'numeric',
  hour12: true,
  minute: '2-digit'
})

export function checkIsValidEventTiming(meetingOccurrences: MeetingOccurrence[]) {
  return meetingOccurrences?.length > 0
}

export function createDateFormatter(meetingOccurrences: MeetingOccurrence[]) {
  const futureMeetings = meetingOccurrences
    .map(meeting => ({
      ...meeting,
      endDateTime: DateTime.fromISO(meeting.end)
    }))
    .filter(meeting => meeting.endDateTime > DateTime.now())
    .sort((a, b) => a.endDateTime.toMillis() - b.endDateTime.toMillis())

  const nextMeeting = futureMeetings.length > 0 ? futureMeetings[0] : meetingOccurrences[0]

  const startTimeLocalDateTime = DateTime.fromISO(nextMeeting.start)
  const endTimeLocalDateTime = DateTime.fromISO(nextMeeting.end)
  const startLocalDateTime = DateTime.fromISO(meetingOccurrences[0].start)
  const endLocalDateTime = DateTime.fromISO(meetingOccurrences.at(-1)!.end)
  
  function formatDateRange(): string {
    const startMonthDayText = startLocalDateTime.toLocaleString({
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    })
    const endMonthDayText = endLocalDateTime.toLocaleString({
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    })

    return `${startMonthDayText} - ${endMonthDayText}`
  }
  
  function formatLocalTimeRange(): string {
    return formatTime(startTimeLocalDateTime) + ' - ' + formatTime(endTimeLocalDateTime)
  }

  return {
    startLocalDateTime,
    endLocalDateTime,
    formatDateRange,
    formatLocalTimeRange
  }
}
