export type MicroFilter = {
    days: FilterValue[]
    times: FilterValue[]
    tags: string[]
}

export type FilterValue = {
    label: string
    value: number | 'Morning' | 'Afternoon' | 'Evening'
}

export const filterWeekDays: FilterValue[] = [
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 }
]

export const filterDays: FilterValue[] = [
    ...filterWeekDays,
    { label: 'Saturday', value: 6 },
    { label: 'Sunday', value: 0 }
]

export const filterTimes: FilterValue[] = [
    { label: 'Morning (8 AM - 12 PM)', value: 'Morning' },
    { label: 'Afternoon (12 PM - 5 PM)', value: 'Afternoon' },
    { label: 'Evening (5 PM - 9 PM)', value: 'Evening' }
]