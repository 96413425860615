import { SessionListItemViewModel } from '@/components/SessionListItem/SessionListItem.interfaces'
import { MicroFilter, FilterValue } from '@/components/SessionSearch/SessionSeach.interfaces'

export function filterByDayAndTime(sessions: SessionListItemViewModel[], microFilter: MicroFilter): SessionListItemViewModel[] {
  let filteredSessions = sessions
  filteredSessions = filterSessionsByDays(filteredSessions, microFilter)
  filteredSessions = filterSessionsByTimes(filteredSessions, microFilter)
  return filteredSessions
}

export function filterSessionsByDays(sessions: SessionListItemViewModel[], microFilter: MicroFilter): SessionListItemViewModel[] {
  if (!microFilter?.days?.length) {
    return sessions
  }
  const days = microFilter.days.map((day: FilterValue) => day.value as number)
  return sessions.filter((session) => days.includes(session.localStartDateTime.getDay()))
}

export function filterSessionsByTimes(sessions: SessionListItemViewModel[], microFilter: MicroFilter): SessionListItemViewModel[] {
  if (!microFilter?.times?.length) {
    return sessions
  }
  const timeValues = microFilter?.times.map(time => time.value)
  let timeFiltered: SessionListItemViewModel[] = []

  if (timeValues.includes('Morning')) {
    timeFiltered = timeFiltered.concat(sessions.filter((session) =>
      session.localStartDateTime.getHours() >= 8
      && session.localStartDateTime.getHours() < 12))
  }
  if (timeValues.includes('Afternoon')) {
    timeFiltered = timeFiltered.concat(sessions.filter((session) =>
      session.localStartDateTime.getHours() >= 12
      && session.localStartDateTime.getHours() < 17))
  }
  if (timeValues.includes('Evening')) {
    timeFiltered = timeFiltered.concat(sessions.filter((session) =>
      session.localStartDateTime.getHours() >= 17
      && session.localStartDateTime.getHours() < 21))
  }

  return timeFiltered
}